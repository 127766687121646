import { Box, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SortOrderType } from '../../types/generated';
import { gql } from '@apollo/client';
import Globals from '../../lib/Globals';
import { useSelector } from 'react-redux';
import { FlattenedUser } from '../../types/user';
import { showError } from '../Toast';
import styles from './Events.module.scss';
import MuiSpinner from '../../mui/MuiSpinner';
import EventFeed from '../eventFeed';
import {
	EventsEventFragment,
	Events_GetEventsWhereQueryVariables,
	useEvents_GetEventsWhereLazyQuery,
} from './__generated__';
import { useDeviceDetection } from '../../hooks/useDeviceDetection';
import rpcShared from '@rockpapercoin/rpc-shared';

/* Putting this selection in a separate file so we can easily move/reuse it
for vendor events, and to make the dashboard components more readable */

const eventsContactFragment = gql`
	fragment eventsContact on Contact {
		id
		status
		firstName
		lastName
		customer {
			id
			email
			userType
			clientUser {
				id
				assignedPlanner {
					id
					organization {
						id
						users {
							id
							role
						}
					}
				}
			}
		}
		vendor {
			users {
				id
				role
			}
		}
		assignedMember {
			id
		}
	}
`;

const eventsEventFragment = gql`
	fragment eventsEvent on Event {
		id
		category
		createdAt
		message
		metadata
		resolvedBy {
			id
		}
		actingUser {
			id
			orgUser {
				id
				firstName
				lastName
				user {
					id
				}
				organization {
					id
					name
					services {
						name
					}
				}
			}
		}
		clientUser {
			id
			firstNameOne
			lastNameOne
			firstNameTwo
			lastNameTwo
			plannerOrgCanPayInvoices
			plannerOrgCanSignContracts
			plannerOrgHasConnectionPermissions
			plannerOrgHasDocumentsPermissions
			user {
				id
				email
			}
			assignedPlanner {
				id
				organization {
					id
					name
					orgType
					services {
						id
						name
					}
					users {
						id
						role
					}
				}
			}
		}
		guestUser {
			user {
				id
				email
			}
		}
		vendor {
			id
			name
			services {
				id
				name
			}
			users {
				id
				role
			}
		}
		orgUser {
			id
			role
			firstName
			lastName
			user {
				id
				email
			}
			organization {
				id
				name
				orgType
				services {
					id
					name
				}
				users {
					id
					role
				}
			}
		}
		plannerUser {
			id
			role
			firstName
			lastName
			user {
				id
			}
			organization {
				id
				name
				orgType
				services {
					id
					name
				}
				users {
					id
					role
				}
			}
		}
		contacts {
			...eventsContact
		}
		contact {
			...eventsContact
		}
		invitation {
			emailAddress
			recipientFirstName
			recipientLastName
		}
		contract {
			id
			title
			sentByUser {
				user {
					id
				}
			}
			vendor {
				id
				users {
					id
					role
				}
			}
			signatures {
				type
				signer {
					id
					orgUser {
						firstName
						lastName
						user {
							id
						}
					}
				}
				customerSignature {
					contact {
						id
						vendor {
							id
							name
							users {
								id
								role
							}
						}
						customer {
							id
							clientUser {
								id
								plannerOrgCanSignContracts
								assignedPlanner {
									id
									organization {
										id
										users {
											id
											role
										}
									}
								}
							}
						}
						assignedMember {
							id
						}
					}
				}
			}
		}
		invoice {
			id
			title
			gratuityAmount
			paymentInstallments {
				id
				name
			}
			contact {
				id
				vendor {
					id
					name
					orgType
					services {
						id
						name
					}
					users {
						id
						role
					}
				}
			}
		}
		document {
			id
			name
			fileSource
			fileType
			customer {
				id
				userType
				clientUser {
					id
					firstNameOne
					lastNameOne
					assignedPlanner {
						id
						organization {
							id
							services {
								id
								name
							}
							users {
								id
								role
							}
						}
					}
				}
				orgUser {
					id
					firstName
					lastName
				}
			}
			organization {
				id
				name
				services {
					id
					name
				}
				users {
					id
					role
				}
			}
		}
		paymentInstallments {
			id
			name
		}
		proposal {
			id
			title
			proposalTemplate {
				id
			}
		}
		inquiry {
			id
			firstName
			lastName
			organization {
				id
				name
				createdAt
				subscription {
					id
					status
					subscriptionPlan {
						id
						type
					}
				}
				groups {
					name
				}
				users {
					id
					role
				}
			}
		}
		emailNotificationGroup {
			groupTitle
		}
	}
	${ eventsContactFragment }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Events_GetEventsWhere = gql`
	query Events_GetEventsWhere(
		$where: EventWhereInput!
		$orderBy: [EventOrderByInput]
		$skip: Int
		$take: Int
	) {
		getEventsWhere(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
			...eventsEvent
		}
	}
	${ eventsEventFragment }
`;

const pageSize = Globals.itemReturnLimit;

export type EventsProps = {
	hideActionButtons?: true;
	hideDismissButtons?: true;
	'data-cy'?: string;
	open?: boolean;
};

export const Events: React.FC<EventsProps> = ( {
	hideActionButtons,
	hideDismissButtons,
	open,
} ) => {
	const user: FlattenedUser = useSelector( ( state: any ) => state.user );
	const { isMobile } = useDeviceDetection();
	const [ events, setEvents ] = useState<EventsEventFragment[]>( [] );
	const [ moreToLoad, setMoreToLoad ] = useState( true );

	const [ getEventsWhere, { loading } ] = useEvents_GetEventsWhereLazyQuery();

	const authenticatedUser = user?.isLoggedIn ? user : undefined;

	const eventsBaseVariables: Pick<
	Events_GetEventsWhereQueryVariables,
	'where' | 'take' | 'orderBy'
	> = useMemo(
		() => ( {
			where: authenticatedUser?.user?.id
				? {
					consumingUsers: { some: { id: authenticatedUser?.user.id } },
					dismissingUsers: {
						every: { NOT: [ { id: authenticatedUser?.user.id } ] },
					},
					NOT: [
						{
							category: {
								in: rpcShared.events.feed.getEventFeedEventsNotHandled(
									authenticatedUser.userType
								),
							},
						},
					],
				  }
				: {},
			orderBy: [ { createdAt: SortOrderType.Desc } ],
		} ),
		[ authenticatedUser ]
	);

	const loadEvents = useCallback(
		async ( skip: number, take: number ) => {
			const response = await getEventsWhere( {
				variables: {
					...eventsBaseVariables,
					skip,
					take,
				},
			} );
			if ( response.error ) {
				showError( response.error );
			} else if ( response.data?.getEventsWhere ) {
				setMoreToLoad( response.data.getEventsWhere.length >= pageSize );
				setEvents( ( prevState ) =>
					skip === 0
						? response.data?.getEventsWhere || []
						: [ ...prevState, ...( response.data?.getEventsWhere || [] ) ]
				);
			}
		},
		[ getEventsWhere, eventsBaseVariables ]
	);

	useEffect( () => {
		if ( ( open || typeof open === 'undefined' ) && authenticatedUser ) {
			loadEvents( 0, pageSize );
		}
	}, [
		loadEvents,
		authenticatedUser,
		open
	] );

	return (
		<Box component='section'>
			{ loading && events.length === 0 ? (
				<Box className={ styles.loadingContainer }>
					<MuiSpinner />
				</Box>
			) : events.length ? (
				<EventFeed
					events={ events }
					moreToLoad={ moreToLoad }
					loadMore={ () => loadEvents( events.length, pageSize ) }
					refresh={ () => loadEvents( 0, events.length ) }
					hideActionButtons={ hideActionButtons }
					hideDismissButtons={ hideDismissButtons }
					hideAvatar={ isMobile || ( hideActionButtons && hideDismissButtons ) }
					loading={ loading }
				/>
			) : (
				<Typography>
					Hiya! Check back here for your digital to-do list!
				</Typography>
			) }
		</Box>
	);
};
