import { PossibleTypesMap } from '@apollo/client';

// Apollo works best if it's local cache looks like your data model, so it can save a server round-trip
export const typenameMapping: PossibleTypesMap = {
	ContractForGetContractWhere: [ 'CustomContract', 'SignatureContract' ],
	ContractTemplate: [ 'ContractTemplateForGetContractWhere' ],
	Signature: [
		'SignatureForGetContractWhere',
		'CustomCustomerSignatureForContact',
		'ContactSignature',
	],
	CustomerSignature: [ 'CustomerSignatureForGetContractWhere' ],
	User: [
		'CustomerForGetContractWhere',
		'SignatureUserForGetContractWhere',
		'CustomerSignatureContactCustomer',
		'SignatureUser',
		'ContactCustomerForGetQuickPayment',
		'UserForSignature',
		'BaseUserForGetContractWhere',
		'ActingUserForEvent',
		'SignatureCounterSignerUser',
		'ReducedUser',
	],
	ClientUser: [
		'ClientUserForGetContractWhere',
		'CustomerSignatureContactCustomerClientUser',
		'CalculatedClientUser',
		'ReducedClientUser',
	],
	Service: [ 'ServicesForGetContractWhere', 'ServicesForGetQuickPayment' ],
	Organization: [
		'OrganizationForGetContractWhere',
		'CustomerSignatureContactOrganization',
		'OrganizationWithCount',
		'OrganizationForGetQuickPayment',
		'OrganizationForActingUser',
		'ReducedOrganization',
	],
	Invoice: [ 'InvoiceForGetContractWhere' ],
	OrgUser: [
		'AdminUsersForGetContractWhere',
		'CustomerSignatureOrgUser',
		'CustomerSignatureContactOrganizationAdminUsers',
		'OrgUserForGetContractWhere',
		'CustomerSignatureContactCustomerClientUserAssignedPlanner',
		'OrgUserSignerForGetContractWhere',
		'CalculatedOrgUser',
		'OrgUserForActingUserOnEvent',
		'SignatureCounterSigner',
		'ReducedOrgUser',
	],
	GuestUser: [ 'GuestUserForGetContractWhere' ],
	Contact: [
		'CustomerSignatureContact',
		'ContactForGetContractWhere',
		'ContactForGetQuickPayment',
	],
	PaymentInstallment: [ 'PaymentInstallmentsForGetContractWhere' ],
	Sources: [ 'SourcesWithDefaultSource' ],
	Coupon: [ 'ReducedCoupon' ],
	Resource: [ 'CustomResource' ],
	Resources: [ 'CustomResources' ],
	Sponsor: [ 'CustomSponsor' ],
	CustomDocumentWithDownloadUrl: [ 'CustomDocumentWithUploadUrl' ],
	Folder: [ 'CustomFolder' ],
	Inquiry: [ 'InquiryWithToken' ],
};

/* Object of typename -> field to act as key value for that typename:
ex. we're calling requestingUserBaseId the id for a ResponseObjectForGetContractWhere object */
export const typenamesForCustomMapping: Record<string, string[]> = {
	ResponseObjectForGetContractWhere: [ 'requestingUserBaseId', 'contract.id' ],
};

export const typenamesToSafelyIgnoreInReporting = [
	// These count and a [something] types, and cannot be merged/should be separate
	'ContractsForVendor',
	'ContractsForCustomer',
	'ContractTemplates',
	'Contacts',
	'Invoices',
	'InquiriesForOrganization',
	'Inquiry',
	'InvoicesForCustomer',
	'Documents',
	'ServicesForActingUser',
	'ClientDashboardStats',
	// these are truly just strings - they have a "url" property and could really be represented as strings
	'InvoiceUrl',
	'MarkupUrl',
	'Proposal',
	'Proposals',
	'QuickPaymentLink',
	'SigningUrl',
	'FormattedGlobalVariables',
	// basically just a Feature Flag
	'ReducedFeatureFlag',
	// this is three numbers without IDs
	'CalculateQuickPaymentSubtotal',
	// This is fine as-is
	'Document',
	'QuickPaymentForGetQuickPayment',
	'AuthPayload',
	'AuthedUser',
	'ReturningMetaData',
	'Source',
	'StripeConnectAccount',
	'StripeOnboardingURL',
	'QuickPayment',
	'UploadUrl',
	'StripePlan',
	// Turns out these are two different returns for similar subscription data
	'StripeInvoice',
	'SubscriptionObject',
	'StripeUser',
	'LineItem',
	'PaymentInstallmentDetails',
	'IdempotencyToken',
	'Event',
	'PaymentIntent',
	'ProposalTemplates',
	'ProposalTemplate',
	'InvoiceTemplate',
	'InvoiceTemplateLineItem',
	'InvoiceTemplates',
	'InvoiceTemplatePaymentInstallment',
	'Organizations',
	'PendingInvitations',
	'Invitation',
	'ContractTemplateMarkupUrl',
	'RedirectUrl',
	'TestingGroup',
	'PaymentHistoryItem',
	'InvoiceForPaymentHistoryItem',
	'OrganizationForPaymentHistoryItem',
	'TransactionHistoryPayout',
	'ReturningStripeMetadata',
	'ReducedCustomer',
	'ReducedContact',
	'ReducedInvoice',
	'TransactionHistoryChargeOrRefund',
	'TransactionHistoryItem',
	'TransactionHistory',
	'InquiryForm',
	'InquiryFormField',
	'InquiryFormStyle',
	'OutstandingInvoice',
	'OrgDashboardStats',
	'PaymentsOutstandingSummary',
	'PaymentsSummary',
	'ContractsSummary',
	'Plan',
	'AppSubscription',
	'VerificationToken',
	'EmailNotificationGroup',
];
